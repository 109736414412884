import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useFormik } from "formik";
import * as Yup from "yup";
import { auth,functions } from "auth/firebase";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
       
    },

    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
       
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
     
    },
    root:{
       // backgroundColor: theme.palette.background.dark,
    }
}));
const ForgotPassword = () => {
    const cookies = new Cookies();
    const classes = useStyles();
    const formik = useFormik({
        initialValues: {
          email: "",
        },
        validationSchema: Yup.object({
          email: Yup.string().required("Email  is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            forgotPassword(values?.email)
            resetForm()
        }
    })

    const forgotPassword=async(email)=>{
        let orgName = cookies.get("organization");
        
        const response = await fetch(
            "https://us-central1-collabkare-250512.cloudfunctions.net/sendPasswordResetEmail",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email:email,
                tenantId: orgName,
              }),
            }
          );
    
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const responseData = await response.json();
          console.log(responseData,"---response")

        // try {
        //     await auth.sendPasswordResetEmail(email);
        //     alert('Password reset email sent!');
        //     // Clear email field and handle any navigation or state updates
        //   } catch (error) {
        //     console.error(error);
        //     alert('Error sending password reset email:', error.message);
        //   }
    }

    return (
        <div className={classes.root}>
        <Container component="main" maxWidth="xs" >
            <CssBaseline />
            <div className={classes.paper}>

                <Typography component="h1" variant="h5">
                    Forgot Your Password?
        </Typography>
                <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        error={
                            formik.touched.email &&
                            Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                          onChange={formik.handleChange}
                          onBlur={(e) => {
                            formik.handleBlur(e);
                          }}
                          value={formik.values.email}
                    />


                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Submit
          </Button>
                    <Grid container>
                        
                        <Grid item>
                        <Link to="/login" style={{  color: 'rgb(0, 122, 189)' }}>
                                Back to Login
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <p style={{textAlign:'center'}}>
            Enter your email address and we will send you instructions on how to create a new password.
        </p>
         
        </Container>
        </div>
    )
}
export default ForgotPassword;





