import { signInWithEmailAndPassword } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { functions,storage } from "auth/firebase";
import Cookies from "universal-cookie";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

// actions/authActions.js
const cookies = new Cookies();
export const loginSuccess = (userCredential) => ({
  type: "LOGIN_SUCCESS",
  payload: userCredential, // pass the userCredential or necessary data
});

export const loginFailure = () => ({
  type: "LOGIN_FAILURE",
});

export const logout = () => ({
  type: "LOGOUT",
});

// actions/authActions.js
export const loginAuth = (email, password, tenant) => async (dispatch) => {
  try {
    const auth = firebase.auth();
    auth.tenantId = tenant;
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );

    const userData = await getUserData({ email: userCredential?.user.email });
    // Dispatch LOGIN_SUCCESS with userCredential as payload

    // Get user image URL
    const userImage = await getUserImage(userCredential?.user.email);

    // Add user image URL to userData
    userData.image = userImage;

    // Dispatch LOGIN_SUCCESS with userData as payload
    dispatch(loginSuccess(userData));
    dispatch(loginSuccess(userData));

    // Save authentication state in sessionStorage
    sessionStorage.setItem("userCredential", JSON.stringify(userCredential));

    // // Set up activity listeners
    // document.addEventListener("mousemove", () =>
    //   dispatch({ type: "USER_ACTIVITY" })
    // );
    // document.addEventListener("keypress", () =>
    //   dispatch({ type: "USER_ACTIVITY" })
    // );

    return userCredential;
  } catch (error) {
    dispatch(loginFailure());
  }
};

export const logoutUser = () => (dispatch) => {
  sessionStorage.removeItem("userCredential");
  dispatch(loginFailure());
};

const getUserData = functions.httpsCallable("getUsersInfo");

export const checkExistingSession = () => (dispatch) => {
  const storedUserCredential = JSON.parse(
    sessionStorage.getItem("userCredential")
  );
  if (storedUserCredential) {
    // Dispatch LOGIN_SUCCESS with userCredential as payload
    dispatch(loginSuccess(storedUserCredential));
  }
};

const getUserImage = async (email) => {
  try {
    const urls = await fetchImages(email);

    if (urls?.length > 0) {
      return urls[0];
    } else {
      // Return a dummy image URL if no image is available
      return 'https://example.com/dummy-image.jpg';
    }
  } catch (error) {
    console.error('Error fetching user images:', error);
    // Return a dummy image URL in case of an error
    return 'https://example.com/error-image.jpg';
  }
};


const fetchImages = async (email) => {
  try {
    const result = await storage
      .ref()
      .child(`documents/users_dp/${email}/`)
      .listAll();
    const urlPromises = result.items.map((imageRef) => imageRef.getDownloadURL());
    return Promise.all(urlPromises);
  } catch (error) {
    console.error('Error fetching user images:', error);
    return [];
  }
};
