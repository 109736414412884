export default {
    // private
    dashboard: '/dashboard',
    profile: '/profile',
    security: '/security',
    licenses: '/licenses',
    subscriptions: '/subscriptions',
    support: '/support',
  
    // auth
    login: '/login',
    signup: '/signup',
    setUpUser:'/setupuser',
    forgotPassword: '/forgotpassword',
    policies:'/privacyPolicy',
    terms:'/terms',
    bookDemo:'/bookdemo',
    start:'/start'

};
