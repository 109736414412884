import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SLUGS from '../resources/routesConstants';
import Login from 'components/Login';
import ForgotPassword from 'components/ForgotPassword';
import Terms from 'components/TermsPolicies/Terms';
import PrivatePolicy from 'components/TermsPolicies/Policies';
import Signup from 'components/Signup/signup';
import SetUpUser from 'components/Signup/setUpUser';
import BookDemo from 'components/Signup/BookDemo';
import Start from '../components/Start/index'

function PublicRoutes() {
  // const [initialRedirect, setInitialRedirect] = useState(true);

  // useEffect(() => {
  //   // After the initial redirect to /start, setInitialRedirect to false
  //   if (initialRedirect) {
  //     setInitialRedirect(false);
  //   }
  // }, []);

  return (
    <Switch>
      {/* Redirect to /start only once initially */}
      {/* {initialRedirect &&  */}
      <Redirect from="/" to={SLUGS.start} exact />
      {/* } */}

      {/* Routes */}
      <Route path={SLUGS.start} component={Start} />
      <Route path={SLUGS.login} component={Login} />
      <Route path={SLUGS.signup} component={Signup} />
      <Route path={SLUGS.setUpUser} component={SetUpUser} />
      <Route path={SLUGS.forgotPassword} component={ForgotPassword} />
      <Route path={SLUGS.terms} component={Terms} />
      <Route path={SLUGS.policies} component={PrivatePolicy} />
      <Route path={SLUGS.bookDemo} component={BookDemo} />

      {/* Redirect to /login for subsequent requests */}
      {/* {!initialRedirect &&  */}
      <Redirect to={SLUGS.login} />
      {/* } */}
    </Switch>
  );
}

export default PublicRoutes;
