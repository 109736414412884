import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Link from "@material-ui/core/Link";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { Divider } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { connect } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import EcoIcon from "@material-ui/icons/Eco";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import SecurityIcon from "@material-ui/icons/Security";
import kareManager from "../../assets/appicons/kareManager.png";
import KareMD from "../../assets/appicons/kareMD.png";
import KarePortal from "../../assets/appicons/karePortal.png";
import KarePractice from "../../assets/appicons/karePractice.png";
import KareIntelligence from "../../assets/appicons/kareIntelligence.png";
import Asset5 from "../../assets/icons/Asset-5.svg";
import Img1 from "../../assets/icons/app2.png";
import Cookies from "universal-cookie";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const useStyles = makeStyles((theme) => ({
  roots: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    color: "white",
  },
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    // marginBottom: theme.spacing(4),
    backgroundImage: "-webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%)",
    //'url(../../../../src/assets/images/banner.png)',

    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
  ui: {
    margin: 0,
    padding: 0,
    listStyle: "none",
  },
  appbar: {
    backgroundImage: "-webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%)",
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    // paddingTop: '56.25%', // 16:9
    // width:'400px'
    width: "200px",
    height: "200px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundSize: "185px",
  },
  cardContent: {
    flexGrow: 1,
    textAlign: "center",
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  ll: {
    flexGrow: 1,
  },
  roo: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f6fbfd",
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
    maxWidth: 900,
  },
  ava: {
    width: "100%",
    maxWidth: 500,
    //backgroundColor: theme.palette.background.paper
  },
  ima: {
    marginLeft: "15px",

    backgroundImage: "-webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%)",
  },
  imaa: {
    marginRight: "15px",

    backgroundImage: "-webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%)",
  },
}));
const Start = (props) => {
  const cookies = new Cookies();
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  useEffect(() => {
    cookies.remove("organization");
  }, []);
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar elevation={0}>
        <Toolbar
          className={classes.appbar}
          // style={{backgroundColor:'rgb(0, 64, 135)'}}
        >
          <img src={Asset5} style={{ width: "200px" }} />
          <div className={classes.ll}></div>
          {/* {window._env_.SIGNUP_ENABLE == "true" ? */}
          <Button color="inherit" href="/singup">
            Create Account
          </Button>
          {/* // : ""} */}

          <Button color="inherit" href="/login">
            Login
          </Button>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      {/* <Container  maxWidth="lg">
                <Box my={2}> */}
      <div
        className={classes.mainFeaturedPost}
        // style={{ backgroundImage: `url(${post.image})` }}
      >
        {/* Increase the priority of the hero background image */}
        {
          <img
            style={{ display: "none" }}

            //src={post.image} alt={post.imageText}
          />
        }
        <div className={classes.overlay} />
        <Grid container>
          <Grid item md={6}>
            <div className={classes.mainFeaturedPostContent}>
              <h2 style={{ lineHeight: "42px", fontSize: "30px" }}>
                {
                  "Simplifying the complicated healthcare system with next-generation Smart technology and Collaborative solutions."
                }
              </h2>
              {/* <Typography variant="h5" color="inherit" paragraph>
                                        {post.description}
                                    </Typography>
                                    <Link variant="subtitle1" href="#">
                                        {post.linkText}
                                    </Link> */}
            </div>
          </Grid>
          <Grid item md={6}>
            <img
              style={{ width: "90%", marginTop: "-80px", height: "100%" }}
              src="/static/images/banner.png"
            />
          </Grid>
        </Grid>
      </div>

      <Container className={classes.cardGrid} maxWidth="lg">
        {/* End hero unit */}

        <Grid
          container
          spacing={1}
          direction="row"
          alignItems="center"
          justify="center"
          // style={{ minHeight: '100vh' }}
        >
          {cards.map((res) => (
            <Grid item xs={12} sm={3} key={res.name}>
              <Card className={classes.card}>
                {/* <CardActionArea> */}
                <br />
                <CardMedia
                  className={classes.cardMedia}
                  image={res.image}
                  title="Image title"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {res.name}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    href={res.Link}
                    variant="outlined"
                    className={classes.link}
                  >
                    Login
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
          {/* <Grid item xs={12} sm={6} md={4}>../../assets/icons/
                        <Card className={classes.card}>
                            <br />
                            <CardMedia
                                className={classes.cardMedia}
                                image="/static/images/Asset-10.svg"
                                title="Image title"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Portal
                                   </Typography>

                            </CardContent>
                            <CardActions>
                                <Button fullWidth href={window._env_.PORTAL_URL} variant="outlined" className={classes.link} >
                                    Login
                                </Button>

                            </CardActions>
                        </Card>
                    </Grid> */}
          {/* <Grid item xs={12} sm={6} md={4}>
                        <Card className={classes.card}>
                            <br />
                            <CardMedia
                                className={classes.cardMedia}
                                image="/static/images/Asset-10.svg"
                                title="Image title"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Marketplace
                                   </Typography>

                            </CardContent>
                            <CardActions>
                                <Button fullWidth href={window._env_.MARKET_PLACE} variant="outlined" className={classes.link} >
                                    Login
                                </Button>

                            </CardActions>
                        </Card>
                    </Grid> */}
        </Grid>
      </Container>
      <div style={{ backgroundColor: "#f6fbfd" }}>
        <Container maxWidth="lg">
          <br />
          <div
            style={{
              textAlign: "center",
              color: "#191c1e",
              fontSize: "22px",
              fontWeight: "700",
              marginTop: "20px",
            }}
          >
            <Chip label="EXPERTISE" />
          </div>

          <h2
            style={{ textAlign: "center", color: "#3c96ff", fontSize: "22px" }}
          >
            Features
          </h2>
          <Grid
            container
            spacing={4}
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12} sm={4}>
              <List className={classes.ava}>
                <ListItem>
                  <ListItemText
                    primary={
                      <span style={{ float: "right", textAlign: "right" }}>
                        Health Information Exchange
                      </span>
                    }
                    secondary={
                      <span style={{ float: "right", textAlign: "right" }}>
                        Performance and track aspects like patient progress and
                        outcomes.
                      </span>
                    }
                  />
                  <ListItemAvatar>
                    <Avatar className={classes.ima}>
                      <EcoIcon />
                    </Avatar>
                  </ListItemAvatar>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <span style={{ float: "right", textAlign: "right" }}>
                        FHIR Protocol
                      </span>
                    }
                    secondary={
                      <span style={{ float: "right", textAlign: "right" }}>
                        FHIR is implemented on top of HL7 and the HTTPS
                        protocol.
                      </span>
                    }
                  />
                  <ListItemAvatar>
                    <Avatar className={classes.ima}>
                      <WhatshotIcon />
                    </Avatar>
                  </ListItemAvatar>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <span style={{ float: "right", textAlign: "right" }}>
                        Health Care Data Standards
                      </span>
                    }
                    secondary={
                      <span style={{ float: "right", textAlign: "right" }}>
                        Retrieval of information associated with health care
                        applications.
                      </span>
                    }
                  />
                  <ListItemAvatar>
                    <Avatar className={classes.ima}>
                      <HeadsetMicIcon />
                    </Avatar>
                  </ListItemAvatar>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img style={{ width: "100%" }} src={Img1} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <List className={classes.ava}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={classes.imaa}>
                      <SecurityIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<span>Security</span>}
                    secondary={
                      <span>
                        To ensuring the security and safety of the hospital
                        patients.
                      </span>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={classes.imaa}>
                      <StarBorderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<span>IHE Protocol</span>}
                    secondary={
                      <span>
                        Promotes the standards such as DICOM and HL7 to address.
                      </span>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={classes.imaa}>
                      <HelpOutlineIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<span>Clinical Decision Support</span>}
                    secondary={
                      <span>
                        Provides, staff, patients or other individuals with
                        knowledge and person-specific information.
                      </span>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <br />
        </Container>
      </div>
      <div style={{ backgroundColor: "#fff" }}>
        <Container maxWidth="lg">
          <br />
          <div
            style={{
              textAlign: "center",
              color: "#191c1e",
              fontSize: "22px",
              fontWeight: "700",
              marginTop: "20px",
            }}
          >
            <Chip label="SOLUTIONS" />
          </div>

          <h2
            style={{ textAlign: "center", color: "#3c96ff", fontSize: "22px" }}
          >
            By Technology
          </h2>
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="center"
            justify="center"
            // style={{ minHeight: '100vh' }}
          >
            <Grid item xs={12} sm={6}>
              <img
                src="../../assets/icons/FHIR-Blog.png"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h3>FHIR IG Registry</h3>
              <p>
                FHIR is a platform specification. It describes a general set of
                capabilities that can be used to solve many healthcare data
                exchange problems. Because FHIR is used for all sorts of
                problems in many jurisdictions using a variety of architectures
                and information exchange patterns, the FHIR specification is
                very general and there are many ways to solve a problem. For
                this reason, implementers must make choices and for particular
                solutions to be interoperable, the implementations must make the
                same set of choices.
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h3>Sandboxes</h3>
              <p>
                You can create Sandboxes by project, application, multiple APIs,
                single services or anything else you like. Every Sandbox has a
                unique URL you’ll use when sending requests to your mock
                services. Within a Sandbox you create routes that specify how
                your Sandbox responds to client requests to a particular
                endpoint. Sandbox provides a DSL for quickly modelling mock
                services in Javascript with minimal effort. You can inspect the
                Request and code behaviour to set the Response. For advanced
                scenarios you can use State to store data between requests.
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img
                src="../../assets/icons/admin-ajax.png"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <img
                src="../../assets/icons/download.jpg"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h3>Health Exchange</h3>
              <p>
                Health Information Exchange (HIE) is the mobilization of health
                care information electronically across organizations within a
                region, community or hospital system. HIE provides the
                capability to electronically move clinical information among
                different health care information systems.
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h3>Analytics</h3>
              <p>
                Organizing resources, allocating budgets, identifying potential
                bottlenecks and responding fast to emerging crises are all par
                for the course – and that’s precisely where business
                intelligence can help. Hospitals increasingly use data analysis
                to keep an eye on overall hospital performance and track aspects
                like patient progress and outcomes, track bed occupancy, improve
                bedside care, and correct inefficiencies in the way they deal
                with patients or administer treatment and services.
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img
                src="../../assets/icons/Hospital-performance.png"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
        <div style={{ background: "#f6fbfd", paddingBottom: "30px" }}>
          <Container maxWidth="md">
            <br />
            <div
              style={{
                textAlign: "center",
                color: "#191c1e",
                fontSize: "22px",
                fontWeight: "700",
                marginTop: "20px",
              }}
            >
              <Chip label="Testimonial" />
            </div>
            <h2
              style={{
                textAlign: "center",
                color: "#3c96ff",
                fontSize: "22px",
              }}
            >
              Bring Quality Care to Patients
            </h2>
            <Paper square elevation={0} className={classes.header}>
              <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {tutorialSteps.map((step, index) => (
                  <div key={step.label}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <div>
                        {step.id}
                        <h3 style={{ textAlign: "center" }}>{step.label}</h3>
                      </div>
                    ) : null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
            </Paper>
            <MobileStepper
              className={classes.roo}
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={activeStep}
            />
          </Container>
        </div>
      </div>
      {/* </Box>
            </Container> */}
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <footer className={classes.appbar}>
        {/* Footer */}
        <Container maxWidth="lg" component="footer" className={classes.footer}>
          <Grid container spacing={4} justify="space-evenly">
            {footers.map((footer) => (
              <Grid item xs={6} sm={3} key={footer.title}>
                <Typography variant="h6" gutterBottom>
                  {footer.title}
                </Typography>
                <ul className={classes.ui}>
                  {footer.description.map((item) => (
                    <li key={item}>
                      <Link
                        href="#"
                        variant="subtitle1"
                        color="textSecondary"
                        style={{ color: "white" }}
                      >
                        {item}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
        </Container>
        {/* End footer */}
        <Divider style={{ backgroundColor: "whitesmoke" }} />
        <br />
        <Container maxWidth="lg">
          {/* <Typography variant="body1">My sticky footer can be found here.</Typography> */}

          <Copyright />
        </Container>
        <br />
      </footer>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    MANAGER_URL: "https://cloud.collabkare.app/",
    PORTAL_URL: "https://myaccount.collabkare.app/",
    MARKET_PLACE: "https://cloud.collabkare.app/",
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Start);

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.roots}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
function Copyright() {
  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item xs={12} sm={6}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ color: "white" }}
        >
          {"Copyright © "}
          <Link color="inherit" href="https://staging.collabkare.com/">
            staging.collabkare.com
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div style={{ float: "right" }}>
          <Link color="inherit" href="https://www.facebook.com/CollabKare/">
            <FacebookIcon style={{ color: "white" }} />
          </Link>
          <Link color="inherit" href="https://twitter.com/collabkare/">
            <TwitterIcon style={{ color: "white" }} />
          </Link>
          <Link
            color="inherit"
            href="https://www.linkedin.com/in/collabkare-inc-3968161ab/"
          >
            <LinkedInIcon style={{ color: "white" }} />
          </Link>
        </div>
      </Grid>
    </Grid>
  );
}

const footers = [
  {
    title: "Products",
    description: [
      "cKare MD App",
      "cKare vHospital",
      "ckare Ai",
      "cKare Exchange",
      "cKare Analytic",
    ],
  },
  {
    title: "Solutions",
    description: [
      "FHIR IG Registry",
      "App Gallery",
      "Sand Boxes",
      "Health Exchange",
      "Analytics",
    ],
  },
  {
    title: "Resources",
    description: ["Events", "Blog", "Knowledge Hub", "News"],
  },
  {
    title: "Contact",
    description: [
      "Sagacity Heights H#1-53 plot no. 27, Phase -1, 4 th floor, Kavuri Hills Phase 1 ,Kavuri Hills, Madhapur, Hyderabad, Telangana 500081, India",
      "Phone: +91 (40) 6745 3961",
      "Email: admin@collabkare.com",
    ],
  },
];

const tutorialSteps = [
  {
    label: "Solutions",
    id: "Collabkare Solutions is helping hospitals, health plans and small group practices with practice improvement, peer review and disease specific documentation improvement improvement services through its analytics and dashboards.",
  },
  {
    label: "Expertise",
    id: "Collabkare Solutions is helping hospitals, health plans and small group practices with practice improvement, peer review and disease specific documentation improvement improvement services through its analytics and dashboards.",
  },
  {
    label: "Use Cases",
    id: "Collabkare Solutions is helping hospitals, health plans and small group practices with practice improvement, peer review and disease specific documentation improvement improvement services through its analytics and dashboards.",
  },
];

const cards = [
  {
    name: "Manager",
    //image: "/assets/images/Asset-10.svg",
    image: kareManager,
    Link: "https://cloud.collabkare.app/",
    description: "go to the Manager application",
  },

  {
    name: "KarePractice",
    image: KarePractice,
    //image:"/static/images/Asset-10.svg",
    Link: "https://karepractice.collabkare.com/",
    description: "go to the karePractice application",
  },
  {
    name: "KarePortal",
    image: KarePortal,
    Link: "https://kareportal.collabkare.com/",
    description: "go to the karePortal application",
  },

  {
    name: "KareMd",
    image: KareMD,
    Link: "https://karemd.collabkare.com/",
    description: "go to the kareMd application",
  },
  {
    name: "kareManager",
    image: kareManager,
    Link: "https://karemanager.collabkare.com/",
    description: "go to the kareManager application",
  },
  {
    name: "kareIntelligence",
    image: KareIntelligence,
    Link: "https://karemanager.collabkare.com/",
    description: "go to the kareIntelligence application",
  },
];
